import { Col } from "react-bootstrap";

// import Pinterest from "../../assets/img/pinterest.png";
// import Instagram from "../../assets/img/instagram.png";
// import Facebook from "../../assets/img/facebook.png";

import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useTranslation } from "react-i18next";

function Branding() {
  const { t } = useTranslation();

  return (
    <>
    {/* <Col className="col-md-6 col-lg-4 col-sm-12 col-xs-12 col-12 lion-head m-0 mt-5 p-0 row">
    </Col> */}

    <Col className="col-sm-12 col-12 branding-container pt-2 pb-2 d-flex justify-content-center align-items-center flex-column" id="branding">
      {/* <div className="branding d-flex justify-content-center align-items-center flex-column">
        <h5 className="title">Marsso</h5>
        <p className="sub-title">Paris</p>
      </div> */}
      <div className="description d-flex align-items-center justify-content-center">
        <p>{t("branding.description")}</p>
      </div>
      <div className="footer">
        <p>{t("branding.description_footer")}</p>
      </div>
      <div className="social-container w-100 d-flex justify-content-center align-items-center">
        <div className="social">
          {/* <a href="https://google.com" target="__blank" className="me-3">
            <img src={Pinterest} height="20px" alt="social-pint" />
          </a> */}
          <a href="Https://www.instagram.com/marssoparis" target="__blank" className="me-3" name="Instagram links" title="Instagram links">
            <FontAwesomeIcon icon={faInstagram} className="brand-instagram" style={{height: "25px"}}/> 
          </a>
          {/* <a href="https://google.com" target="__blank">
            <img src={Facebook} height="20px" alt="social-fb" />
          </a> */}
        </div>
      </div>
    </Col>
    </>
  );
}

export default Branding;
