import { Col, Row, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Address from "./clients/address";

import API from "../../services/api";
import { useState } from "react";


const Client = ({ values, dispatcher }) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState();

  const toogle = () => setModal(!modal);

  const fillFormWithAddress = (formData, address, type = "shipping") => {
    Object.keys(address).forEach((key) => {
      if (values.shipping[key]) {
        formData.append(type + "." + key, values.shipping[key]);
      }
    });
  };

  function emailValidator(val) {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return val.match(validRegex) !== null;
  }

  function phoneValidator(val) {
    var regex = /^[+][(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
    return val.match(regex) !== null;
  }

  const addressValidator = (address) => {
    let isValid = false;

    if (
      address.title &&
      address.first_name &&
      address.last_name &&
      address.address1 &&
      address.postcode &&
      address.city &&
      address.country &&
      address.mobile_phone_number
    ) {
      isValid = true;
    }

    if (!phoneValidator(address.mobile_phone_number)) {
      toast.error(t("api.phone_number_bad_format"));
      return false;
    }

    return (
      emailValidator(address.email) &&
      isValid &&
      phoneValidator(address.mobile_phone_number)
    );
  };

  const sendFormPayment = async (formData) => {
    const response = await API.sendPayment(formData);
    if (!response) {
      toast.error(t("api.error"));
    }
  };

  const submitPayment = async () => {
    const formData = new FormData();

    fillFormWithAddress(formData, values.shipping);

    if (!values.isBillingEqual) {
      fillFormWithAddress(formData, values.billing, "billing");
    } else {
      fillFormWithAddress(formData, values.shipping, "billing");
    }

    formData.append("material", values.material);
    formData.append("size", values.size);
    formData.append("paymentType", values.paymentType);
    formData.append("return", window.location.origin);

    if (
      !values.size ||
      !values.material ||
      !addressValidator(values.shipping) ||
      !(
        values.isBillingEqual ||
        (!values.isBillingEqual && addressValidator(values.billing))
      )
    ) {
      toast.error(t("error.missing_field"));
      return;
    }

    toast.promise(sendFormPayment(formData), {
      loading: t("submit.check_fields"),
      error: t("error.missing_field"),
      success: t("submit.can_submit_payment"),
    });
  };

  return (
    <Row style={{ minHeight: "500px" }} id="shipping-container">
      <Address
        values={values?.shipping}
        dispatcher={dispatcher}
        parentType={"shipping"}
      />
      <Col lg="12" sm="12" className={`d-flex justify-content-center ${!values.isBillingEqual ? 'mb-5' : 'mb-3'}`}>
        <div className="form-group">
          <label className="d-flex align-items-center py-3">
            <input
              type="checkbox"
              name="declare_billing"
              checked={values.isBillingEqual}
              onChange={(e) =>
                dispatcher({ type: "isBillingEqual", value: e.target.checked })
              }
            />
            <span className="ms-2 txt-futura">
              {t("client.declare_billing")}
            </span>
          </label>
        </div>
      </Col>
      {!values.isBillingEqual && (
        <Address
          values={values?.billing}
          dispatcher={dispatcher}
          parentType={"billing"}
        />
      )}
      <Col className="col-12 d-flex justify-content-center ">
        <div className="w-100 d-flex justify-content-center align-items-center flex-column mt-2 mb-2 buy-container-client">
          <p className="cgv-button"  onClick={toogle}>
             {t('product.accept_cgv')}
          </p>
          <Button className="product-btn-buy px-5 py-3" onClick={submitPayment} name="buy">
            {t("product.buy")}
          </Button>
        </div>
      </Col>
      <Toaster />
      <Modal isOpen={modal} toggle={toogle} size="lg" className="modal-size-guide w-100" style={{minHeight: '500px', width: '90%'}}>
        <ModalHeader className="d-flex justify-content-end py-0 border-0" onClick={toogle}><FontAwesomeIcon icon={faTimes} className="close-icon" /> </ModalHeader>
        <ModalBody className=" py-2">
          <iframe title="cgv" src="https://ovation-bijoux.com/content/cgv-3" style={{width: "100%", height: '500px'}}></iframe>
        </ModalBody>
      </Modal>
    </Row>
  );
};

export default Client;
