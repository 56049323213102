import React from "react";
import Select from "react-select";

const optionsList = {
  AF: "Afghanistan",
  ZA: "Afrique du sud",
  AX: "Åland, îles",
  AL: "Albanie",
  DZ: "Algérie",
  DE: "Allemagne",
  AD: "Andorre",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarctique",
  AG: "Antigua et barbuda",
  SA: "Arabie saoudite",
  AR: "Argentine",
  AM: "Arménie",
  AW: "Aruba",
  AU: "Australie",
  AT: "Autriche",
  AZ: "Azerbaïdjan",
  BS: "Bahamas",
  BH: "Bahreïn",
  BD: "Bangladesh",
  BB: "Barbade",
  BY: "Bélarus",
  BE: "Belgique",
  BZ: "Belize",
  BJ: "Bénin",
  BM: "Bermudes",
  BT: "Bhoutan",
  BO: "Bolivie, l'état plurinational de",
  BQ: "Bonaire, saint eustache et saba",
  BA: "Bosnie herzégovine",
  BW: "Botswana",
  BV: "Bouvet, île",
  BR: "Brésil",
  BN: "Brunei darussalam",
  BG: "Bulgarie",
  BF: "Burkina faso",
  BI: "Burundi",
  KY: "Caïmans, îles",
  KH: "Cambodge",
  CM: "Cameroun",
  CA: "Canada",
  CV: "Cap vert",
  CF: "Centrafricaine, république",
  CL: "Chili",
  CN: "Chine",
  CX: "Christmas, île",
  CY: "Chypre",
  CC: "Cocos (keeling), îles",
  CO: "Colombie",
  KM: "Comores",
  CG: "Congo",
  CD: "Congo, la république démocratique du",
  CK: "Cook, îles",
  KR: "Corée, république de",
  KP: "Corée, république populaire démocratique de",
  CR: "Costa rica",
  CI: "Côte d'ivoire",
  HR: "Croatie",
  CU: "Cuba",
  CW: "Curaçao",
  DK: "Danemark",
  DJ: "Djibouti",
  DO: "Dominicaine, république",
  DM: "Dominique",
  EG: "Égypte",
  SV: "El salvador",
  AE: "Émirats arabes unis",
  EC: "Équateur",
  ER: "Érythrée",
  ES: "Espagne",
  EE: "Estonie",
  US: "États unis",
  ET: "Éthiopie",
  FK: "Falkland, îles (malvinas)",
  FO: "Féroé, îles",
  FJ: "Fidji",
  FI: "Finlande",
  FR: "France",
  GA: "Gabon",
  GM: "Gambie",
  GE: "Géorgie",
  GS: "Géorgie du sud et les îles sandwich du sud",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Grèce",
  GD: "Grenade",
  GL: "Groenland",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernesey",
  GN: "Guinée",
  GW: "Guinée bissau",
  GQ: "Guinée équatoriale",
  GY: "Guyana",
  GF: "Guyane française",
  HT: "Haïti",
  HM: "Heard et îles macdonald, île",
  HN: "Honduras",
  HK: "Hong kong",
  HU: "Hongrie",
  IM: "Île de man",
  UM: "Îles mineures éloignées des états unis",
  VG: "Îles vierges britanniques",
  VI: "Îles vierges des états unis",
  IN: "Inde",
  ID: "Indonésie",
  IR: "Iran, république islamique d'",
  IQ: "Iraq",
  IE: "Irlande",
  IS: "Islande",
  IL: "Israël",
  IT: "Italie",
  JM: "Jamaïque",
  JP: "Japon",
  JE: "Jersey",
  JO: "Jordanie",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KG: "Kirghizistan",
  KI: "Kiribati",
  KW: "Koweït",
  LA: "Lao, république démocratique populaire",
  LS: "Lesotho",
  LV: "Lettonie",
  LB: "Liban",
  LR: "Libéria",
  LY: "Libye",
  LI: "Liechtenstein",
  LT: "Lituanie",
  LU: "Luxembourg",
  MO: "Macao",
  MK: "Macédoine, l'ex république yougoslave de",
  MG: "Madagascar",
  MY: "Malaisie",
  MW: "Malawi",
  MV: "Maldives",
  ML: "Mali",
  MT: "Malte",
  MP: "Mariannes du nord, îles",
  MA: "Maroc",
  MH: "Marshall, îles",
  MQ: "Martinique",
  MU: "Maurice",
  MR: "Mauritanie",
  YT: "Mayotte",
  MX: "Mexique",
  FM: "Micronésie, états fédérés de",
  MD: "Moldova, république de",
  MC: "Monaco",
  MN: "Mongolie",
  ME: "Monténégro",
  MS: "Montserrat",
  MZ: "Mozambique",
  MM: "Myanmar",
  NA: "Namibie",
  NR: "Nauru",
  NP: "Népal",
  NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigéria",
  NU: "Niué",
  NF: "Norfolk, île",
  NO: "Norvège",
  NC: "Nouvelle calédonie",
  NZ: "Nouvelle zélande",
  IO: "Océan indien, territoire britannique de l'",
  OM: "Oman",
  UG: "Ouganda",
  UZ: "Ouzbékistan",
  PK: "Pakistan",
  PW: "Palaos",
  PS: "Palestinien occupé, territoire",
  PA: "Panama",
  PG: "Papouasie nouvelle guinée",
  PY: "Paraguay",
  NL: "Pays bas",
  PE: "Pérou",
  PH: "Philippines",
  PN: "Pitcairn",
  PL: "Pologne",
  PF: "Polynésie française",
  PR: "Porto rico",
  PT: "Portugal",
  QA: "Qatar",
  RE: "Réunion",
  RO: "Roumanie",
  GB: "Royaume uni",
  RU: "Russie, fédération de",
  RW: "Rwanda",
  EH: "Sahara occidental",
  BL: "Saint barthélemy",
  SH: "Sainte hélène, ascension et tristan da cunha",
  LC: "Sainte lucie",
  KN: "Saint kitts et nevis",
  SM: "Saint marin",
  MF: "Saint martin(partie française)",
  SX: "Saint martin (partie néerlandaise)",
  PM: "Saint pierre et miquelon",
  VA: "Saint siège (état de la cité du vatican)",
  VC: "Saint vincent et les grenadines",
  SB: "Salomon, îles",
  WS: "Samoa",
  AS: "Samoa américaines",
  ST: "Sao tomé et principe",
  SN: "Sénégal",
  RS: "Serbie",
  SC: "Seychelles",
  SL: "Sierra leone",
  SG: "Singapour",
  SK: "Slovaquie",
  SI: "Slovénie",
  SO: "Somalie",
  SD: "Soudan",
  SS: "Soudan du sud",
  LK: "Sri lanka",
  SE: "Suède",
  CH: "Suisse",
  SR: "Suriname",
  SJ: "Svalbard et île jan mayen",
  SZ: "Swaziland",
  SY: "Syrienne, république arabe",
  TJ: "Tadjikistan",
  TW: "Taïwan, province de chine",
  TZ: "Tanzanie, république unie de",
  TD: "Tchad",
  CZ: "Tchèque, république",
  TF: "Terres australes françaises",
  TH: "Thaïlande",
  TL: "Timor leste",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinité et tobago",
  TN: "Tunisie",
  TM: "Turkménistan",
  TC: "Turks et caïcos, îles",
  TR: "Turquie",
  TV: "Tuvalu",
  UA: "Ukraine",
  UY: "Uruguay",
  VU: "Vanuatu",
  VE: "Venezuela, république bolivarienne du",
  VN: "Viet nam",
  WF: "Wallis et futuna",
  YE: "Yémen",
  ZM: "Zambie",
  ZW: "Zimbabwe​​​​​",
};

const options = [
  { value: "AF", label: "Afghanistan" },
  { value: "ZA", label: "Afrique du sud" },
  { value: "AX", label: "Åland, îles" },
  { value: "AL", label: "Albanie" },
  { value: "DZ", label: "Algérie" },
  { value: "DE", label: "Allemagne" },
  { value: "AD", label: "Andorre" },
  { value: "AO", label: "Angola" },
  { value: "AI", label: "Anguilla" },
  { value: "AQ", label: "Antarctique" },
  { value: "AG", label: "Antigua et barbuda" },
  { value: "SA", label: "Arabie saoudite" },
  { value: "AR", label: "Argentine" },
  { value: "AM", label: "Arménie" },
  { value: "AW", label: "Aruba" },
  { value: "AU", label: "Australie" },
  { value: "AT", label: "Autriche" },
  { value: "AZ", label: "Azerbaïdjan" },
  { value: "BS", label: "Bahamas" },
  { value: "BH", label: "Bahreïn" },
  { value: "BD", label: "Bangladesh" },
  { value: "BB", label: "Barbade" },
  { value: "BY", label: "Bélarus" },
  { value: "BE", label: "Belgique" },
  { value: "BZ", label: "Belize" },
  { value: "BJ", label: "Bénin" },
  { value: "BM", label: "Bermudes" },
  { value: "BT", label: "Bhoutan" },
  { value: "BO", label: "Bolivie, l'état plurinational de" },
  { value: "BQ", label: "Bonaire, saint eustache et saba" },
  { value: "BA", label: "Bosnie herzégovine" },
  { value: "BW", label: "Botswana" },
  { value: "BV", label: "Bouvet, île" },
  { value: "BR", label: "Brésil" },
  { value: "BN", label: "Brunei darussalam" },
  { value: "BG", label: "Bulgarie" },
  { value: "BF", label: "Burkina faso" },
  { value: "BI", label: "Burundi" },
  { value: "KY", label: "Caïmans, îles" },
  { value: "KH", label: "Cambodge" },
  { value: "CM", label: "Cameroun" },
  { value: "CA", label: "Canada" },
  { value: "CV", label: "Cap vert" },
  { value: "CF", label: "Centrafricaine, république" },
  { value: "CL", label: "Chili" },
  { value: "CN", label: "Chine" },
  { value: "CX", label: "Christmas, île" },
  { value: "CY", label: "Chypre" },
  { value: "CC", label: "Cocos (keeling), îles" },
  { value: "CO", label: "Colombie" },
  { value: "KM", label: "Comores" },
  { value: "CG", label: "Congo" },
  { value: "CD", label: "Congo, la république démocratique du" },
  { value: "CK", label: "Cook, îles" },
  { value: "KR", label: "Corée, république de" },
  { value: "KP", label: "Corée, république populaire démocratique de" },
  { value: "CR", label: "Costa rica" },
  { value: "CI", label: "Côte d'ivoire" },
  { value: "HR", label: "Croatie" },
  { value: "CU", label: "Cuba" },
  { value: "CW", label: "Curaçao" },
  { value: "DK", label: "Danemark" },
  { value: "DJ", label: "Djibouti" },
  { value: "DO", label: "Dominicaine, république" },
  { value: "DM", label: "Dominique" },
  { value: "EG", label: "Égypte" },
  { value: "SV", label: "El salvador" },
  { value: "AE", label: "Émirats arabes unis" },
  { value: "EC", label: "Équateur" },
  { value: "ER", label: "Érythrée" },
  { value: "ES", label: "Espagne" },
  { value: "EE", label: "Estonie" },
  { value: "US", label: "États unis" },
  { value: "ET", label: "Éthiopie" },
  { value: "FK", label: "Falkland, îles (malvinas)" },
  { value: "FO", label: "Féroé, îles" },
  { value: "FJ", label: "Fidji" },
  { value: "FI", label: "Finlande" },
  { value: "FR", label: "France" },
  { value: "GA", label: "Gabon" },
  { value: "GM", label: "Gambie" },
  { value: "GE", label: "Géorgie" },
  { value: "GS", label: "Géorgie du sud et les îles sandwich du sud" },
  { value: "GH", label: "Ghana" },
  { value: "GI", label: "Gibraltar" },
  { value: "GR", label: "Grèce" },
  { value: "GD", label: "Grenade" },
  { value: "GL", label: "Groenland" },
  { value: "GP", label: "Guadeloupe" },
  { value: "GU", label: "Guam" },
  { value: "GT", label: "Guatemala" },
  { value: "GG", label: "Guernesey" },
  { value: "GN", label: "Guinée" },
  { value: "GW", label: "Guinée bissau" },
  { value: "GQ", label: "Guinée équatoriale" },
  { value: "GY", label: "Guyana" },
  { value: "GF", label: "Guyane française" },
  { value: "HT", label: "Haïti" },
  { value: "HM", label: "Heard et îles macdonald, île" },
  { value: "HN", label: "Honduras" },
  { value: "HK", label: "Hong kong" },
  { value: "HU", label: "Hongrie" },
  { value: "IM", label: "Île de man" },
  { value: "UM", label: "Îles mineures éloignées des états unis" },
  { value: "VG", label: "Îles vierges britanniques" },
  { value: "VI", label: "Îles vierges des états unis" },
  { value: "IN", label: "Inde" },
  { value: "ID", label: "Indonésie" },
  { value: "IR", label: "Iran, république islamique d'" },
  { value: "IQ", label: "Iraq" },
  { value: "IE", label: "Irlande" },
  { value: "IS", label: "Islande" },
  { value: "IL", label: "Israël" },
  { value: "IT", label: "Italie" },
  { value: "JM", label: "Jamaïque" },
  { value: "JP", label: "Japon" },
  { value: "JE", label: "Jersey" },
  { value: "JO", label: "Jordanie" },
  { value: "KZ", label: "Kazakhstan" },
  { value: "KE", label: "Kenya" },
  { value: "KG", label: "Kirghizistan" },
  { value: "KI", label: "Kiribati" },
  { value: "KW", label: "Koweït" },
  { value: "LA", label: "Lao, république démocratique populaire" },
  { value: "LS", label: "Lesotho" },
  { value: "LV", label: "Lettonie" },
  { value: "LB", label: "Liban" },
  { value: "LR", label: "Libéria" },
  { value: "LY", label: "Libye" },
  { value: "LI", label: "Liechtenstein" },
  { value: "LT", label: "Lituanie" },
  { value: "LU", label: "Luxembourg" },
  { value: "MO", label: "Macao" },
  { value: "MK", label: "Macédoine, l'ex république yougoslave de" },
  { value: "MG", label: "Madagascar" },
  { value: "MY", label: "Malaisie" },
  { value: "MW", label: "Malawi" },
  { value: "MV", label: "Maldives" },
  { value: "ML", label: "Mali" },
  { value: "MT", label: "Malte" },
  { value: "MP", label: "Mariannes du nord, îles" },
  { value: "MA", label: "Maroc" },
  { value: "MH", label: "Marshall, îles" },
  { value: "MQ", label: "Martinique" },
  { value: "MU", label: "Maurice" },
  { value: "MR", label: "Mauritanie" },
  { value: "YT", label: "Mayotte" },
  { value: "MX", label: "Mexique" },
  { value: "FM", label: "Micronésie, états fédérés de" },
  { value: "MD", label: "Moldova, république de" },
  { value: "MC", label: "Monaco" },
  { value: "MN", label: "Mongolie" },
  { value: "ME", label: "Monténégro" },
  { value: "MS", label: "Montserrat" },
  { value: "MZ", label: "Mozambique" },
  { value: "MM", label: "Myanmar" },
  { value: "NA", label: "Namibie" },
  { value: "NR", label: "Nauru" },
  { value: "NP", label: "Népal" },
  { value: "NI", label: "Nicaragua" },
  { value: "NE", label: "Niger" },
  { value: "NG", label: "Nigéria" },
  { value: "NU", label: "Niué" },
  { value: "NF", label: "Norfolk, île" },
  { value: "NO", label: "Norvège" },
  { value: "NC", label: "Nouvelle calédonie" },
  { value: "NZ", label: "Nouvelle zélande" },
  { value: "IO", label: "Océan indien, territoire britannique de l'" },
  { value: "OM", label: "Oman" },
  { value: "UG", label: "Ouganda" },
  { value: "UZ", label: "Ouzbékistan" },
  { value: "PK", label: "Pakistan" },
  { value: "PW", label: "Palaos" },
  { value: "PS", label: "Palestinien occupé, territoire" },
  { value: "PA", label: "Panama" },
  { value: "PG", label: "Papouasie nouvelle guinée" },
  { value: "PY", label: "Paraguay" },
  { value: "NL", label: "Pays bas" },
  { value: "PE", label: "Pérou" },
  { value: "PH", label: "Philippines" },
  { value: "PN", label: "Pitcairn" },
  { value: "PL", label: "Pologne" },
  { value: "PF", label: "Polynésie française" },
  { value: "PR", label: "Porto rico" },
  { value: "PT", label: "Portugal" },
  { value: "QA", label: "Qatar" },
  { value: "RE", label: "Réunion" },
  { value: "RO", label: "Roumanie" },
  { value: "GB", label: "Royaume uni" },
  { value: "RU", label: "Russie, fédération de" },
  { value: "RW", label: "Rwanda" },
  { value: "EH", label: "Sahara occidental" },
  { value: "BL", label: "Saint barthélemy" },
  { value: "SH", label: "Sainte hélène, ascension et tristan da cunha" },
  { value: "LC", label: "Sainte lucie" },
  { value: "KN", label: "Saint kitts et nevis" },
  { value: "SM", label: "Saint marin" },
  { value: "MF", label: "Saint martin(partie française)" },
  { value: "SX", label: "Saint martin (partie néerlandaise)" },
  { value: "PM", label: "Saint pierre et miquelon" },
  { value: "VA", label: "Saint siège (état de la cité du vatican)" },
  { value: "VC", label: "Saint vincent et les grenadines" },
  { value: "SB", label: "Salomon, îles" },
  { value: "WS", label: "Samoa" },
  { value: "AS", label: "Samoa américaines" },
  { value: "ST", label: "Sao tomé et principe" },
  { value: "SN", label: "Sénégal" },
  { value: "RS", label: "Serbie" },
  { value: "SC", label: "Seychelles" },
  { value: "SL", label: "Sierra leone" },
  { value: "SG", label: "Singapour" },
  { value: "SK", label: "Slovaquie" },
  { value: "SI", label: "Slovénie" },
  { value: "SO", label: "Somalie" },
  { value: "SD", label: "Soudan" },
  { value: "SS", label: "Soudan du sud" },
  { value: "LK", label: "Sri lanka" },
  { value: "SE", label: "Suède" },
  { value: "CH", label: "Suisse" },
  { value: "SR", label: "Suriname" },
  { value: "SJ", label: "Svalbard et île jan mayen" },
  { value: "SZ", label: "Swaziland" },
  { value: "SY", label: "Syrienne, république arabe" },
  { value: "TJ", label: "Tadjikistan" },
  { value: "TW", label: "Taïwan, province de chine" },
  { value: "TZ", label: "Tanzanie, république unie de" },
  { value: "TD", label: "Tchad" },
  { value: "CZ", label: "Tchèque, république" },
  { value: "TF", label: "Terres australes françaises" },
  { value: "TH", label: "Thaïlande" },
  { value: "TL", label: "Timor leste" },
  { value: "TG", label: "Togo" },
  { value: "TK", label: "Tokelau" },
  { value: "TO", label: "Tonga" },
  { value: "TT", label: "Trinité et tobago" },
  { value: "TN", label: "Tunisie" },
  { value: "TM", label: "Turkménistan" },
  { value: "TC", label: "Turks et caïcos, îles" },
  { value: "TR", label: "Turquie" },
  { value: "TV", label: "Tuvalu" },
  { value: "UA", label: "Ukraine" },
  { value: "UY", label: "Uruguay" },
  { value: "VU", label: "Vanuatu" },
  { value: "VE", label: "Venezuela, république bolivarienne du" },
  { value: "VN", label: "Viet nam" },
  { value: "WF", label: "Wallis et futuna" },
  { value: "YE", label: "Yémen" },
  { value: "ZM", label: "Zambie" },
  { value: "ZW", label: "Zimbabwe​​​​​" },
];

const SelectCountry = ({inputId, labelledby, value, placeholder, onChange }) => {
  console.log(value, { value, label: optionsList[value] });
  return (
    <Select
      className="select-country"
      inputId={inputId}
      aria-labelledby={labelledby}
      name="country"
      placeholder={placeholder}
      value={{ value, label: optionsList[value] }}
      onChange={(option) => {
        onChange(option.value);
      }}
      options={options}
    />
  );
};

export default SelectCountry;
