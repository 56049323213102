import { Col, Form } from "react-bootstrap";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import SelectCountry from "./country";

// parent = shipping / billing
const Address = ({ values, dispatcher, parentType }) => {
  const { t } = useTranslation();

  const isBilling = parentType === "billing";

  return (
    <Col
      className="col-12 row client m-0 p-0 d-flex justify-content-center"
      id={isBilling ? "Billing" : "shipping"}
    >
      <Col xs="12" sm="12" md="6" lg="6" className="row">
        <Col className="col-12 client-title mb-5 text-center">
          {t(isBilling ? "client.billing_title" : "client.title")}
        </Col>
        <Col sm="6" className="px-2 py-2">
          <label hidden id={`aria-label-civility_`+parentType} htmlFor={`civility_`+parentType}>Civility</label>
          <Select
            aria-labelledby={`aria-label-civility_`+parentType}
            inputId={`civility_`+parentType}
            id={`civility_`+parentType}
            title="civility"
            name={`civility_`+parentType}
            options={[
              { label: t("client.MR"), value: "mr" },
              { label: t("client.MME"), value: "mme" },
            ]}
            className="select"
            value={{
              label: t(`client.${values.title.toUpperCase()}`),
              value: values.titles,
            }}
            onChange={(opt) =>
              dispatcher({
                type: parentType,
                value: { ...values, title: opt.value },
              })
            }
          />
        </Col>{" "}
        <Col sm="6" className="px-2 py-2">
          <label hidden htmlFor={`email_`+parentType}>Email</label>
          <Form.Control
            id={`email_`+parentType}
            name="email"
            value={values?.email}
            onChange={(e) =>
              dispatcher({
                type: parentType,
                value: { ...values, email: e.target.value },
              })
            }
            placeholder={t("client.email")}
          />
        </Col>
        <Col sm="6" className="px-2 py-2">
          <label hidden htmlFor={`firstname_`+parentType}>Firstname</label>
          <Form.Control
            id={`firstname_`+parentType}
            name="firstname"
            value={values?.firstname}
            onChange={(e) =>
              dispatcher({
                type: parentType,
                value: { ...values, first_name: e.target.value },
              })
            }
            placeholder={t("client.firstname")}
          />
        </Col>
        <Col sm="6" className="px-2 py-2">
          <label hidden htmlFor={`lastname_`+parentType}>Lastname</label>
          <Form.Control
            id={`lastname_`+parentType}
            name="lastname"
            value={values?.lastname}
            placeholder={t("client.lastname")}
            onChange={(e) =>
              dispatcher({
                type: parentType,
                value: { ...values, last_name: e.target.value },
              })
            }
          />
        </Col>
        <Col sm="12" className="px-2 py-2">
          <label hidden htmlFor={`address_`+parentType}>Address</label>
          <Form.Control
            id={`address_`+parentType}
            name="address1"
            value={values?.address1}
            type="address"
            placeholder={t("client.address")}
            onChange={(e) =>
              dispatcher({
                type: parentType,
                value: { ...values, address1: e.target.value },
              })
            }
          />
        </Col>
        <Col sm="12" className="px-2 py-2 mb-2">
          <label hidden htmlFor={`address2_`+parentType}>Address 2</label>
          <Form.Control
            id={`address2_`+parentType}
            name="address2"
            value={values?.address2}
            placeholder={t("client.address_2")}
            onChange={(e) =>
              dispatcher({
                type: parentType,
                value: { ...values, address2: e.target.value },
              })
            }
          />
        </Col>
        <Col sm="6" className="px-2 py-2">
          <label hidden htmlFor={`postcode_`+parentType}>Postcode</label>
          <Form.Control
            id={`postcode_`+parentType}
            name="postcode"
            type="string"
            value={values?.postcode}
            placeholder={t("client.postcode")}
            onChange={(e) =>
              dispatcher({
                type: parentType,
                value: { ...values, postcode: e.target.value },
              })
            }
          />
        </Col>
        <Col sm="6" className="px-2 py-2">
          <label hidden htmlFor={`city_`+parentType}>City</label>
          <Form.Control
            id={`city_`+parentType}
            name="city"
            type="string"
            value={values?.city}
            placeholder={t("client.city")}
            onChange={(e) =>
              dispatcher({
                type: parentType,
                value: { ...values, city: e.target.value },
              })
            }
          />
        </Col>
        <Col sm="6" className="px-2 py-2">
          <label hidden id="label-select-size" htmlFor={`country_`+parentType}>Country</label>
          <SelectCountry
            labelledby="label-select-size"
            inputId={`country_`+parentType}
            value={values?.country}
            placeholder={t("client.country")}
            onChange={(codeCountry) =>
              dispatcher({
                type: parentType,
                value: { ...values, country: codeCountry },
              })
            }
          />
        </Col>
        <Col sm="6" className="px-2 py-2">
        <label hidden htmlFor={`phone_`+parentType}>Phone</label>
          <Form.Control
            id={`phone_`+parentType}
            name="mobile_phone_number"
            value={values?.mobile_phone_number}
            type="tel"
            placeholder={t("client.phone")}
            onChange={(e) =>
              dispatcher({
                type: parentType,
                value: { ...values, mobile_phone_number: e.target.value },
              })
            }
          />
        </Col>
      </Col>
    </Col>
  );
};

export default Address;
