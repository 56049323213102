import { Button, Col } from "react-bootstrap";

import { useTranslation } from "react-i18next";

const Header = () => {
  const { i18n } = useTranslation();

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang)
  }

  return (
    <Col className="col-12 row bandeau m-0 p-0">
      <Col xs="12" lg="12">
        <div className="branding d-flex justify-content-center align-items-center flex-column">
          <h5 className="title">Marsso</h5>
          <p className="sub-title">Paris</p>
        </div>
      </Col>
      <Col className="languages d-flex flex-row">
          <Button name="Language French" className="fr" onClick={() => handleChangeLanguage('fr')}>FR</Button>
          <Button name="Language English" className="en" onClick={() => handleChangeLanguage('en')}>EN</Button>
      </Col>
    </Col>
  );
};

export default Header;
