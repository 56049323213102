import { useState } from "react";
import { Col, Modal, ModalHeader, ModalBody } from "reactstrap";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useTranslation } from "react-i18next";

const Footer = ({ onChange }) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState();
  const [modalLegales, setModalLegales] = useState();

  const toogle = () => setModal(!modal);
  const toogleLegales = () => setModalLegales(!modalLegales);

  const currentDate = new Date();
  
  return (
    <Col className="col-12 row footer row m-0 p-0 mt-4">
      <Col className="col-12 branding d-flex justify-content-center align-items-center flex-column">
        <h5 className="title">Marsso</h5>
        <p className="sub-title">Paris</p>
      </Col>
      <Col sm="4" className="links">
        <p name="contact us" className="text-black title" onClick={toogle}>{t("footer.contact_us")}</p>
      </Col>
      <Col sm="4" className="links">
        <a name="about us"  href="#branding" className="title">{t("footer.about_us")}</a>
      </Col>
      <Col sm="4" className="links">
        <p name="legales" className="title" onClick={toogleLegales}>{t("footer.legales")}</p>
      </Col>
      <Col xs="12" className="end">
        © {currentDate.getFullYear()} MARSSO par Ovation - {t("footer.all_rights_reserved")} - SARL Ovation Bijoux - SIRET °90453658800026
      </Col>
      <Modal isOpen={modal} toggle={toogle} size="lg" className="modal-size-guide h-100">
        <ModalHeader className="d-flex justify-content-end py-0 border-0" onClick={toogle}><FontAwesomeIcon icon={faTimes} className="close-icon" /> </ModalHeader>
        <ModalBody className="d-flex flex-column justify-content-center align-items-center py-2">
            <a name="Company phone number" className="text-black title" href="tel:+33643214303" subject="Call us">{t('footer.call_us')} : +33643214303</a><br/>
            <a name="Company email" className="text-black title" href="mailto:info@marsso-paris.com" subject="Call us">{t('footer.write_us')} : info@marsso-paris.com</a><br/>
        </ModalBody>
      </Modal>

      <Modal isOpen={modalLegales} toggle={toogleLegales} size="lg" className="modal-size-guide w-100" style={{minHeight: '500px', width: '90%'}}>
        <ModalHeader className="d-flex justify-content-end py-0 border-0" onClick={toogleLegales}><FontAwesomeIcon icon={faTimes} className="close-icon" /> </ModalHeader>
        <ModalBody className=" py-2">
          <h5>Ce site est la propriété de E.U.R.L. OVATION BIJOUX</h5>
          <h4>Marsso Paris</h4>
          <p>
          4 rue de la Côte Radieuse<br/>
          66280 Saleilles<br/>
          Capital social 1 000 € (euros)<br/>
          </p>
          <p>
          Vous pouvez joindre notre service clientèle par téléphone du lundi au vendredi de 9h à 12h et de 13h à 17h au +33 (0)6 43 21 43 03 (appel non surtaxé) <br/>
          Vous pouvez joindre nos agents commerciaux par e-mail à l'adresse contact(@)info@marsso-paris.com
          </p>

          <h4>Protection des données personnelles</h4>
          <p>
            Conformément à la loi 78-17 du 6 janvier 1978 refondue par la loi 2004-801 du 6 août 2004, la collecte et le traitement de vos données à caractère personnel ont fait l'objet d'une déclaration auprès de la Commission Nationale de l'Informatique et des Libertés (C.N.I.L.). Ovation Bijoux est seule détentrice des informations vous concernant.
          </p>
          <p>
            Toutes les données que vous nous confiez le sont afin de pouvoir traiter vos commandes. En vertu de la loi n° 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés, vous disposez auprès du service client de Ovation Bijoux. D’un droit d'accès, de consultation, de modification, de rectification et de suppression des données que vous nous avez communiquées.
          </p>
          <p>
            Hébergement : Infomaniak Network SA - Rue Eugène-Marziano 25 - 1227 Les Acacias (Suisse)
          </p>

        </ModalBody>
      </Modal>

       
    </Col>
  );
};

export default Footer;
