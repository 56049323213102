/* eslint-disable  react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.css";
import "swiper/css";
import "swiper/css/navigation";
import "./assets/css/main.css";

import "./translation";

import { useReducer, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import API from "./services/api";

// import { useTranslation } from 'react-i18next';

import Header from "./components/elements/header";
// import LionPresentation from "./components/elements/lion-presentation";
import Branding from "./components/elements/branding";
import Product from "./components/elements/product";
import Client from "./components/elements/client";
import Footer from "./components/elements/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";

const getMaterialPrice = (material) => {
  switch (material) {
    case "gold18k":
      return 2900;
    case "gold9k":
      return 1200;
    case "silver925":
      return 360;
    case "fakeGold":
      return 380;
    default: return 2900;
  }
}

const defaultPayment = {
  shipping: {
    title: "mr",
    first_name: "",
    last_name: "",
    address1: "",
    address2: "",
    postcode: "",
    city: "",
    country: "FR",
    mobile_phone_number: "+33",
    email: "",
  },
  billing: {
    title: "mr",
    first_name: "",
    last_name: "",
    address1: "",
    address2: "",
    postcode: "",
    city: "",
    country: "FR",
    mobile_phone_number: "",
    email: "",
  },
  isBillingEqual: true,
  paymentType: null,
  material: "fakeGold",
  size: 60,
  currentPrice: getMaterialPrice("fakeGold"),
};



const formReducer = (state, action) => {
  const currentPrice = {};
  if (action.type === "material") {
    let price = getMaterialPrice(action.value);

    currentPrice["currentPrice"] = price;
  }

  return { ...state, [action.type]: action.value, ...currentPrice };
};

function App() {
  const [form, setForm] = useReducer(formReducer, defaultPayment);
  const [success, setSuccess] = useState();
  const [successSent, setSuccessSent] = useState(false);

  const sendEmail = (type = false, body) => {
    if (!successSent) {
      setSuccessSent(true);
      API.sendEmail(type, body);
    }
  }

  useEffect(() => {
    // TEST: http://localhost:3000/?success=true&body=eyJuYW1lIjoiRGltaXRyaSIsInNpemUiOjYzLCJlbWFpbCI6ImRpbWl0cmkuaEBjb2RlLXguZnIiLCJwcmljZSI6InRlc3QiLCJtYXRlcmlhbCI6ImdvbGQxOGsifQ==
    const queryParams = new URLSearchParams(window.location.search);
    const isSuccess = queryParams.get('success');
    const body = queryParams.get('body');
    if (isSuccess !== undefined && isSuccess !== null) {
      sendEmail(isSuccess === 'true', body);
      setSuccess(isSuccess === 'true');
    }
  }, []);

  return (
    <>
    <Row className="m-0 p-0 w-100 h-100">
      <Header />
      {/* <LionPresentation /> */}
      <div className="mb-5 mt-2"></div>
      <Product values={form} dispatcher={setForm} />
      <Branding />
      <Client values={form} dispatcher={setForm} />
      <Footer />
    </Row>
    {
      success && (<div id="modal-success-container">
          <div id="modal-content-success">
              <Row>
                <Col className="col-12 d-flex justify-content-end py-2">
                      <Button 
                        size="sm" 
                        className="border-0 bg-white text-black d-flex justify-content-center align-items-center"
                        onClick={() => setSuccess(false)}
                      >
                        <FontAwesomeIcon id="size-info" className="me-2" icon={faTimes}/>
                      </Button>
                </Col>
                <Col sm="12" className="mt-5 px-4">
                    <div className="w-100 d-flex justify-content-center">
                        <FontAwesomeIcon className="check"  icon={faCircleCheck}/>
                    </div>
                    <h4 className="text-center w-100 mt-4">{t('submit.thanks')}</h4>
                    <p className="text-center w-100">{t('submit.signet_ring_in_progress')}</p>
                    <p className="text-center w-100">{t('submit.thanks_email')}</p>
                </Col>
              </Row>
          </div>
      </div>)
    }
    </>
  );
}

export default App;
