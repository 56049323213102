import { useState, lazy, Suspense } from "react";
import { Col, Row, Button,  } from "react-bootstrap";
import { UncontrolledTooltip, Modal, ModalBody, ModalHeader } from "reactstrap";
import Select from "react-select";

import { faCircleInfo, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "swiper/css/pagination";

import Oney from "../../assets/img/oney.webp";
import TableGuide from "../../assets/img/tableau_correspondances_bagues.webp";

import { useTranslation } from "react-i18next";

const SwipeMobile = lazy(() => import("./products/img-mobile"));
const SwipeWep = lazy(() => import("./products/img-web.jsx"));

function Product({ values, dispatcher }) {
  const { t } = useTranslation();
  const [openDescr, setOpenDescr] = useState();
  const [openDeliv, setOpenDeliv] = useState();
  const [openCustom, setOpenCustom] = useState();
  const [sizeModal, setSizeModal] = useState();

  const toogleSizeModal = () => setSizeModal(!sizeModal);

  const getSize = () => {
    const options = [];
    for (let i = 57; i <= 72; i++) {
      options.push({ label: i, value: i });
    }

    return options;
  };

  const getMaterials = () => {
    return [
      {label: t('lion.silver925'), value: 'silver925'},
      {label: t('lion.fakeGold'), value: 'fakeGold'},
      {label: t('lion.gold9k'), value: 'gold9k'},
      {label: t('lion.gold18k'), value: 'gold18k'},
    ];
  }

  const changeOney = (type) => {
    let pType = null;
    if (values.paymentType !== type) {
      pType = type;
    }

    dispatcher({ type: "paymentType", value: pType });
  };

  return (
    <Col className="col-12 m-0 mt-4 row product-container">
      {/* WEB IMG */}
      <Col className="mobile-hidden col-12  swip-container col-md-6 col-lg-7 d-flex justify-content-center align-items-center">
        <Suspense>
          <SwipeWep values={values} />
        </Suspense>
      </Col>

      {/* MOBILE IMG */}
      <Col className="web-hidden col-12 swip-container col-md-6 d-flex justify-content-center align-item-center">
        <Suspense>
          <SwipeMobile values={values} />
        </Suspense>
      </Col>

      <Col className="col-12 col-md-6 col-lg-4 product-info d-flex flex-column align-items-center justify-content-center">
        <div className="w-100 d-flex justify-content-center align-items-center flex-column">
          <p className="title">LionKiss</p>
          <p className="sub-title text-center">
            {t("product.title")} <br />
            {values.material === "gold18k" && t("lion.gold18k")}
            {values.material === "gold9k" && t("lion.gold9k")}
            {values.material === "silver925" && t("lion.silver925")}
            {values.material === "fakeGold" && t("lion.fakeGold")}
          </p>
          <p className="price text-center m-0">{values.currentPrice}€</p>
        </div>

        <div className="w-75 mx-auto d-flex justify-content-center align-items-center marge-product ">
          <Row className="d-flex align-items-center justify-content-center flex-column">
            <Col className="p-0">
              <p id="oney_info" className="p-0 m-0 oney-text"><FontAwesomeIcon icon={faCircleInfo}/> {t('oney.pay_multiple_times')}</p>
              <UncontrolledTooltip placement="top" target="oney_info">
                {t("oney.description")}
              </UncontrolledTooltip>
            </Col>
            <Col className="d-flex align-items-center justify-content-center pb-1 oney">
              <img src={Oney} width="50px" alt="oney" />
              <button
                name="oney-x3"
                className={`btn ${values.paymentType === 3 ? "selected" : ""}`}
                onClick={() => changeOney(3)}
              >
                3x
              </button>
              <button
                name="oney-x4"
                className={`btn ${values.paymentType === 4 ? "selected" : ""}`}
                onClick={() => changeOney(4)}
              >
                4x
              </button>
            </Col>
            <Col className="pb-2 text-center">
              {values.paymentType !== null && (
                <span className="price">
                  {values.paymentType} x{" "}
                  {(values.currentPrice / values.paymentType).toFixed(2)} €{" "}
                </span>
              )}
            </Col>
          </Row>
        </div>

     
        <div className="w-100 marge-product d-flex justify-content-center mb-1">
          <p className="p-0 m-0 size-guide">{t('product.materials')}</p>
        </div>     
        <div className="material d-flex align-items-center justify-content-center flex-column">
          <label hidden id="label-select-material" htmlFor="select-material">materials</label>
          <Select
              aria-labelledby="label-select-material"
              inputId="select-material"
              className="select-material"
              name="country"
              value={{ label: t('lion.'+values.material), value: values.material }}
              placeholder={t("materials")}
              options={getMaterials()}
              onChange={(option) =>
                dispatcher({ type: "material", value: option.value })
              }
          />
        </div>

        <div className="w-100 marge-product d-flex justify-content-center">
            <Button name="guide-size" color="default" className="size-guide" onClick={() => toogleSizeModal()}>{t('product.see_size_guide')}</Button>
        </div>
        <div className="w-100 d-flex justify-content-center align-items-center ">
        <label hidden id="label-select-size" htmlFor="id-select-size">Ring Size</label>
          <Select
            className="select-size"
            inputId="id-select-size"
            aria-labelledby="label-select-size"
            name="ring-size"
            value={{ label: values.size, value: values.size }}
            placeholder={t("")}
            options={getSize()}
            onChange={(option) =>
              dispatcher({ type: "size", value: option.value })
            }
          />
        
        </div>

        <div className="product-info-tabs w-75 mx-auto marge-product">
          <div
            className="tab bd-top d-flex align-items-center justify-content-start px-2"
            onClick={() => setOpenDescr(!openDescr)}
          >
            <div>
              <div className={`tab-title ${openDescr && "open"}`}>
                {t("product.description")} {openDescr ? "-" : "+"}
              </div>
              <div className={`child ${openDescr && "open"}`}>
                {t("product.info.description")}
              </div>
            </div>
          </div>
          <div
            className="tab d-flex align-items-center justify-content-start px-2"
            onClick={() => setOpenDeliv(!openDeliv)}
          >
            <div className="w-100">
              <div className={`tab-title ${openDeliv && "open"}`}>
                {t("product.delivery_guaranties")} {openDeliv ? "-" : "+"}
              </div>
              <div className={`child ${openDeliv && "open"}`}>
                {t("product.info.delivery.1")} <br />
                {t("product.info.delivery.2")} <br />
                {t("product.info.delivery.3")} <br />
                {t("product.info.delivery.4")} <br />
                {t("product.info.delivery.5")} <br />
              </div>
            </div>
          </div>
          <div
            className="tab d-flex align-items-center justify-content-start px-2"
            onClick={() => setOpenCustom(!openCustom)}
          >
            <div className="w-100">
              <div className={`tab-title ${openCustom && "open"}`}>
                {t("product.specifications")} {openCustom ? "-" : "+"}
              </div>
              <div className={`child ${openCustom && "open"}`}>
                {t("product.info.spec.1")}
                <br />
                {t("product.info.spec.2")}
                <br />
                {t("product.info.spec.3")}
                <br />
                {t("product.info.spec.4")}
                <br />
                {t("product.info.spec.5")}
                <br />
                {t("product.info.spec.5_5")}
                <br />
                {t("product.info.spec.6")}
                <br />
                {t("product.info.spec.7")}
                <br />
                {t("product.info.spec.8")}
                <br />
                {t("product.info.spec.9")}
              </div>
            </div>
          </div>
        </div>
        <div className="w-100 d-flex justify-content-center align-items-center marge-product">
          <a className="product-btn-buy px-5 py-3" href="#shipping" alt="go-buy-signet-ring" name="Go to the form">
            {t("product.buy")}
          </a>
        </div>
        <Modal isOpen={sizeModal} toggle={toogleSizeModal} size="lg" className="modal-size-guide">
          <ModalHeader className="d-flex justify-content-end py-0" onClick={toogleSizeModal}><FontAwesomeIcon icon={faTimes} className="close-icon" /> </ModalHeader>
          <ModalBody>
            <img src={TableGuide} width="100%" alt="guide" />
          </ModalBody>
        </Modal>
      </Col>
    </Col>
  );
}

export default Product;
